import { Flex, FlexProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { HEADER_Z_INDEX } from "../styles/zIndex";
interface HeaderProps extends FlexProps {
  children: ReactNode;
}

export const Header = (props: HeaderProps) => {
  const { children, ...rest } = props;
  return (
    <Flex
      as="header"
      position="fixed"
      top="0"
      left="0"
      color="blackAlpha.700"
      width="100%"
      height="16"
      shadow="lg"
      zIndex={HEADER_Z_INDEX}
      backgroundColor="white"
      {...rest}
    >
      {children}
    </Flex>
  );
};
