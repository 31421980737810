import { Flex, FlexProps, theme } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface MainProps extends FlexProps {
  children?: ReactNode;
}

export const Main = (props: MainProps) => {
  const { children, ...rest } = props;
  return (
    <Flex
      position="fixed"
      top="16"
      left="0"
      as="main"
      flexDirection="column"
      overflowY="auto"
      minHeight={`calc(100% - ${theme.space[16]})`}
      maxHeight={`calc(100% - ${theme.space[16]})`}
      minWidth="100%"
      maxWidth="100%"
      {...rest}
    >
      {children}
    </Flex>
  );
};
