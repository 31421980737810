import { Flex, FlexProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface LayoutProps extends FlexProps {
  children: ReactNode;
}

export const Layout = (props: LayoutProps) => {
  const { children, ...rest } = props;
  return (
    <Flex flexDirection="column" minWidth="100vw" minHeight="100vh" {...rest}>
      {children}
    </Flex>
  );
};
